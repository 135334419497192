
import MaterialFormV3 from '@/components/Forms/ResourcesV3/MaterialFormV3.vue'
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import ResourceChangeLogV3 from '@/components/ResourceChangeLogV3.vue'
import { MaterialV3 } from '@/globals/javascript/models/resources-version-3/MaterialV3'

export default defineComponent({
  name: 'NewMaterialDialogV3',
  props: {
    materialToEdit: {
      type: Object as PropType<MaterialV3>,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    MaterialFormV3,
    ResourceChangeLogV3,
  },

})
