
import TypeFormV3 from '@/components/Forms/ResourcesV3/TypeFormV3.vue'
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import ResourceChangeLogV3 from '@/components/ResourceChangeLogV3.vue'
import { TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'

export default defineComponent({
  name: 'NewTypeDialogV3',
  props: {
    typeToEdit: {
      type: Object as PropType<TypeV3>,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    TypeFormV3,
    ResourceChangeLogV3,
  },

})
